@import '../../../themes.scss';
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins/_breakpoints";

@mixin flexbox() {
    display: -ms-flexbox; //ie10 needs this
    display: flex;
}

.navigation {
    @include flexbox();
    width: 100%;
    justify-content: center;
    overflow: hidden;

    .navigation-item {
        @include flexbox();
        padding: 2px;
        min-height: 80px;
        align-items: stretch; //make child link the full size of this container
        text-align: center;
    }  

    .navigationLink {
        @include flexbox();
        width: 100%; //make the width of the link fill the navigation-item
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        border-radius: 0 !important;

        .navigationText {
            margin-right: 10px;
            width: 120px;
        }

        .navigationImage {
            display: none;
        }

        @include media-breakpoint-up(xl) {
            .navigationImage {
                display: block;
                width: 60px;
                height: 30px;
                font-size: 10px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
            }
        }

        @include themify($themes) {
            border: 1px solid themed('navigationBackgroundColor');
        }
    }

    .previous {
        @include themify($themes) {
            background-color: themed('navigationBackgroundColor_Completed');
            color: themed('navigationFontColor_Completed');
            border: themed('navigationBorder_Completed') solid 1px !important;
        }

        .alloy-image {
            @include themify($themes) {
                background-image: themed('navigationAlloyImage_Completed');
            }

            object-fit: contain;
        }

        .cosmetic-image {
            @include themify($themes) {
                background-image: themed('navigationCosmeticImage_Completed');
            }

            object-fit: contain;
        }

        .gap-image {
            @include themify($themes) {
                background-image: themed('navigationGapImage_Completed');
            }

            object-fit: contain;
        }

        .tyre-image {
            @include themify($themes) {
                background-image: themed('navigationTyreImage_Completed');
            }

            object-fit: contain;
        }

        .warranty-image {
            @include themify($themes) {
                background-image: themed('navigationWarrantyImage_Completed');
            }

            object-fit: contain;
        }

        &:hover {
            filter: brightness(95%);
        }
    }

    .next {
        @include themify($themes) {
            background-color: themed('navigationBackgroundColor');
            color: themed('navigationFontColor');
            border: themed('navigationBorderColor') solid 1px !important;
        }

        .alloy-image {
            @include themify($themes) {
                background-image: themed('navigationAlloyImage');
            }

            object-fit: contain;
        }

        .cosmetic-image {
            @include themify($themes) {
                background-image: themed('navigationCosmeticImage');
            }

            object-fit: contain;
        }

        .gap-image {
            @include themify($themes) {
                background-image: themed('navigationGapImage');
            }

            object-fit: contain;
        }

        .tyre-image {
            @include themify($themes) {
                background-image: themed('navigationTyreImage');
            }

            object-fit: contain;
        }

        .warranty-image {
            @include themify($themes) {
                background-image: themed('navigationWarrantyImage');
            }

            object-fit: contain;
        }
    }

    .active {
        @include themify($themes) {
            background-color: themed('navigationBackgroundColorActive') !important;
            color: themed('navigationFontColorActive') !important;
            border: themed('navigationBorderColorActive') solid 1px !important;
        }

        .alloy-image {
            @include themify($themes) {
                background-image: themed('navigationAlloyImage_Active');
            }

            object-fit: contain;
        }

        .cosmetic-image {
            @include themify($themes) {
                background-image: themed('navigationCosmeticImage_Active');
            }

            object-fit: contain;
        }

        .gap-image {
            @include themify($themes) {
                background-image: themed('navigationGapImage_Active');
            }

            object-fit: contain;
        }

        .tyre-image {
            @include themify($themes) {
                background-image: themed('navigationTyreImage_Active');
            }

            object-fit: contain;
        }

        .warranty-image {
            @include themify($themes) {
                background-image: themed('navigationWarrantyImage_Active');
            }

            object-fit: contain;
        }
    }
}

@media only screen and (max-width: 574.99px) {
    .mobile-navigation {
        @include flexbox();
        flex-wrap: nowrap;
        width: 100%;
        overflow: hidden;
        margin: 2px 0;

        .navigation-item {
            @include flexbox();
            border: none;
            align-items: stretch;
        }

        .navigation-item-active {
            flex-grow: 1;
        }

        .navigationLink {
            @include flexbox();
            width: 100%; //make the width of the link fill the navigation-item
            align-items: center;
            border-width: 1px 1px 1px 0;
            border-style: solid;
            border-radius: 0 !important;
            border-color: #f2f2f2; //use a fixed light grey colour to outline the mobile navigation items.
            padding: 5px 8px;

            .navigationText {
                font-size: 0.6rem;
            }

            .navigationNumber {
                font-size: 1.2rem;
            }
        }

        .navigationLink:first-child {
            border-left-width: 1px;
        }

        .previous {
            @include themify($themes) {
                background-color: themed('navigationBackgroundColor_Completed');
                color: themed('navigationFontColor_Completed');
            }

            &:hover {
                filter: brightness(95%);
            }
        }

        .next {
            @include themify($themes) {
                background-color: themed('navigationBackgroundColor');
                color: themed('navigationFontColor');
            }
        }

        .active {
            @include themify($themes) {
                background-color: themed('navigationBackgroundColorActive') !important;
                color: themed('navigationFontColorActive') !important;
            }

            .navigationNumber {
                margin-right: 5px;
            }
        }
    }
}
