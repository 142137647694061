.generic-container {
    height: 100vh;
    width: 100vw;
    z-index: 99;
    position: absolute;
    top: 0%;
    background-color: white;
    left: 0%;
    text-align: center;
}
