@import '../../../themes.scss';

.product-panel {
    background-color: white;
    display: block;
    margin: 10px;

    .card-product {
        border: none;
        padding: 10px;

        @include themify($themes) {
            background-color: themed('cardBodyBackgroundColor');
            border-color: themed('productCardBorder');
            color: themed('cardBodyFontColor');
        }

        border: 2px solid;
        border-radius: 0px;

        .card-header-product {
            background: inherit;
            text-align: center;
            border: none;

            .title {
                font-weight: 700;
            }

            .sub-title {
                font-weight: 600;
                font-size: 0.80em;
                margin-top: 10px;
            }
        }

        .card-body-product {
            padding-top: 0;
            font-size: 0.80rem;

            ul {
                padding: 0;
                text-align: left;
            }
        }

        .card-text-product {
            font-weight: bold;
        }

        .card-footer-product {
            background: inherit;
            text-align: center;
            border: none;
        }

        .defacto-container {
            text-align: center;
            margin: 5px 0;

            .defacto-image {
                object-fit: contain;
                height: 60px;
            }
        }

        .add-to-basket-button {
            padding: 0.25rem 3rem;
            border: 2px solid;
            width: 100%;
            margin: 5px 0;

            @include themify($themes) {
                background-color: themed('addToBasketBackgroundColor');
                border-color: themed('addToBasketBorderColor');
                color: themed('addToBasketFontColor');
            }

            &:hover {
                filter: brightness(95%);
            }
        }

        .product-invoice-value {
            width: 100% !important;
            margin: 5px 0;

            div {
                width: 100% !important;
            }

            input {
                width: 100% !important;
            }

            .form-group {
                margin-bottom: 0;
            }
        }

        .product-lookup {
            width: 100% !important;
            margin: 5px 0;

            div {
                width: 100% !important;
            }

            input {
                width: 100% !important;
            }

            select {
                width: 100% !important;
            }

            textarea {
                width: 100% !important;
            }
        }
    }
}

.product-video-panel {
    margin-bottom: 20px;
    min-height: 300px;
    height: 100%;
    width: 100%;
}

.product-video-details {
    height: 100%;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.no-product {
    margin: 1rem auto;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.react-player > div {
    width: 100% !important;
    height: 100% !important;
}

.w-bottom-bar-lower {
    div {
        @include themify($themes) {
            background-color: themed('videoBarColor');
        }
    }

    &:hover {
        @include themify($themes) {
            background-color: themed('videoBarColor');
        }
    }
}

.w-big-play-button {
    div {
        @include themify($themes) {
            background-color: themed('videoPlayButtonColor') !important;
        }
    }
}

.product-see-more-panel {
    padding-left: 0px;
    margin-top: 10px;
}

.product-see-more-details {
    margin-top: 0px;
    border: 1px solid;
    padding-left: 15px;
}

.product-accordian-header {
    @include themify($themes) {
        background-color: themed('productDocumentHeaderBackgroundColor');
        color: themed('productDocumentHeaderFontColor');
        border-color: themed('productDocumentHeaderBackgroundColor')
    }

    table {
        width: 100%;
    }

    td {
        padding-left: 15px;
    }
}

.product-policy-document-panel {
    padding-left: 0px;
    padding-right: 0px;
    margin: 1rem 0;
}

.product-policy-document-details {
    margin-top: 0px;

    table {
        width: 100%;
        border: 1px solid grey;
    }

    tr {
        @include themify($themes) {
            background-color: themed('productDocumentRowBackgroundColor');
            color: themed('productDocumentRowFontColor');
        }

        border-top: 1px solid grey;
    }

    th {
        padding-left: 15px;
    }

    td {
        padding-left: 15px;
    }

    font-size: .8rem;
}

.product-icon {
    text-align: end;
    align-content: end;
    padding-right: 15px;
    font-size: 1rem;

    a {
        padding-right: 0px;
    }
}

.product-right-chevron-link-color {

    a {
        color: black;
    }
}

.fixed-bottom {
    position: fixed;
    bottom: 10%;
    right: 10%;
}

.product-image-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.product-image-alloy {
    @include themify($themes) {
        background-image: themed('alloyProductImagePath');
        background-size: cover;
    }
}

.product-image-cosmetic {
    @include themify($themes) {
        background-image: themed('cosmeticProductImagePath');
        background-size: cover;
    }
}

.product-image-gap {
    @include themify($themes) {
        background-image: themed('gapProductImagePath');
        background-size: cover;
    }
}

.product-image-tyre {
    @include themify($themes) {
        background-image: themed('tyreProductImagePath');
        background-size: cover;
    }
}
