.backdrop {
    height: 100%;
    width: 100%;
    z-index: 98;
    position: absolute;
    top: 0%;
    background-color: rgba(0,0,0, 0.0);
    left: 0%;
    text-align: center;
}
