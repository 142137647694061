@import '../../themes.scss';

.header-fixed {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;

    header {
        width: 100%;
        display: flex;
    }
}

.basket-icon {
    margin-left: auto;
    margin-right: 20px;
}

.main-positioning {
    display: flex;
    flex-direction: column;
}