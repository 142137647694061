#toast-container > div {
    opacity: 1;
    box-shadow: none;
}

#toast-container > .theme-autotrust {
    border: #fff 2px solid;
    background-color: #379fdc !important;
    color: #fff !important;
    background-image: none !important;
    padding-left: 15px;

    button {
        color: #fff !important;
    }
}

#toast-container > .theme-heritageAutomotive {
    border: #EF650A 2px solid;
    background-color: #fff !important;
    color: #000 !important;
    background-image: none !important;
    padding-left: 15px;

    button {
        color: #EF650A !important;
    }
}

#toast-container > .theme-marshall {
    border: #BA0C2F 2px solid;
    background-color: #fff !important;
    color: #000 !important;
    background-image: none !important;
    padding-left: 15px;


    button {
        color: #000 !important;
    }
}

#toast-container > .theme-trustFord {
    border: #F4F4F4 2px solid;
    background-color: #3fb6e4 !important;
    color: #fff !important;
    background-image: none !important;
    padding-left: 15px;


    button {
        color: #fff !important;
    }
}
