.has-error .field input, .has-error .field select.form-control, .has-error .field textarea.form-control{
    border: solid 1px Red;
}

.has-error .input
{
    color:Red;
    white-space:nowrap;
}

@media only screen and (max-width: 520px) {
    .has-error .input {
        white-space: pre-wrap;
    }
}