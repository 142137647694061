body {
}

main {
    background: none !important;
}

.vehicle-detail-panel {
    background-color: white;
    border-radius: 8px;
    padding: 1rem;
}

.grecaptcha-badge {
    visibility: hidden;
}

.disclaimer {
    max-width: 300px;
    font-size: 0.5rem;
    margin-bottom: 0;
}

@media only screen and (max-width: 420px) {
    .captcha-disclaimer {
        max-width: 100%;
        font-size: 0.8rem;
    }
}