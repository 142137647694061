.customer-detail-panel {
    background-color: white;
    border-radius: 8px;
    padding: 1rem;
    display: block;
}

.postcode-finder{
    width: 325px;
}

.input-group-append{
    z-index: 0;
}

@media only screen and (max-width: 420px) {
    .postcode-finder {
        width: 100%;
    }
}
