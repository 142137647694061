@import '../../../themes.scss';

.main-container {
    @include themify($themes) {
        background-color: themed('cardBodyBackgroundColor');
    }

    min-width: 300px;
    margin: 2rem auto 0 auto;
    border-radius: 0;
    padding: 2rem;
    text-align: center;

    h3 {
        @include themify($themes) {
            color: themed('cardBodyFontColor');
        }

        font-weight: 400;
        margin:0;
    }

    p {
        @include themify($themes) {
            color: themed('cardBodyFontColor');
        }
        margin: 0px;
    }
}

.policy-info-container {
    @include themify($themes) {
        border: themed('shoppingBasketPillColor') solid 1px;
    }

    min-width: 280px;
    padding: 1rem;
    background-color: #fff;
    margin: 1rem auto;
    text-align: left;
}

.policy-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
