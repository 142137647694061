﻿.field {

    input {
        width: 325px;
    }

    select {
        width: 325px;
    }

    textarea {
        width: 325px;
        height: 110px;
    }

    &.checkbox {
        input {
            width: inherit;
            padding-left: 10px;
        }
    }

    &.date {
        input {
            width: 150px;
            padding-left: 10px;
            height: 38px;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        &.readonly {
            input {
                background-color: #e9ecef;
            }
        }
    }

    &.warning {
        input {
            border: solid 3px Red;
        }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #C0C0C0;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #C0C0C0;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #C0C0C0;
    }

    &.small {
        input {
            width: 150px;
        }

        select {
            width: 150px;
        }
    }

    &.fullscreen {
        input {
            width: 100%;
        }

        textarea {
            width: 100%;
        }
    }
}

.field-text-explanation {
    font-size: 0.6rem;
    color: #6c757d;
}

@media only screen and (max-width: 420px) {
    .field {
        input {
            width: 100%;
            padding: 1.0rem 0.75rem;
        }

        select {
            width: 100%;
        }

        textarea {
            width: 100%;
        }

        &.checkbox {
            input {
                width: 100%;
                height: 30px;
            }
        }

        &.date {
            input {
                width: 100%;
                padding: 1rem 0.75rem;
            }
        }

        &.small {
            input {
                width: 100%;
            }

            select {
                width: 100%;
            }
        }
    }

    .input-group-prepend {
        width: 100%;
    }

    .date-flex-width {
        flex-grow: 1;
    }

    .field-text-explanation {
        font-size: 0.8rem;
    }
}
