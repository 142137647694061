@import '../../../themes.scss';

.shopping-basket-icon {
    @include themify($themes) {
        color: themed('shoppingBasketIconColor');
    }

    font-size: 2rem;
    cursor: pointer;
}

.shopping-basket-pill {
    @include themify($themes) {
        background-color: themed('shoppingBasketPillColor');
        color: themed('shoppingBasketPillFontColor');
    }

    margin-left: 0.5rem;
}

.shoppingBasketContainer {
    height: 100px;
    display: flex;
    align-items: center;
    position: relative;
}

.shoppingBasketContainerMain {
    @include themify($themes) {
        background-color: themed('shoppingBasketBodyColor');
    }

    height: 400px;
    width: 400px;
    position: absolute;
    top: 100%;
    right: -30%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(255, 255, 255, 0.6) -2px 2px 2px;
    border-top: white solid 1px;
}

.basket-title {
    @include themify($themes) {
        background-color: themed('shoppingBasketHeaderColor');
        color: themed('shoppingBasketHeaderFontColor')
    }

    height: 10%;
    border-bottom: white solid 1px;
    margin: 0;
    padding: 6px 12px 6px 12px;
}

.basket-list {
    @include themify($themes) {
        color: themed('shoppingBasketBodyFontColor')
    }

    list-style: none;
    margin: 0;
    padding-left: 12px;

    h4 {
        font-size: 1rem;
        font-weight: 700;
    }

    li {
        font-size: 0.9rem;
        font-weight: 500;
    }
}

.basket-icon-bin {
    @include themify($themes) {
        color: themed('shoppingBasketBodyFontColor')
    }

    cursor: pointer;
}

.shoppingFooterContent {
    @include themify($themes) {
        background-color: themed('shoppingBasketFooterColor');
    }

    padding: 4px 12px 12px 12px;
    align-items: flex-end;
    border-top: white solid 1px;
    height: 20%;

    p {
        position: absolute;
    }
}

.item-container-items {
    justify-content: center;
    align-items: center;
    height: 70%;
    margin: 8px 0 4px 0;
    overflow-y: auto;
}


.footerContent {
    @include themify($themes) {
        color: themed('shoppingBasketFooterFontColor');
    }

    list-style: none;
    padding: 0;
    margin: 0;

    li {
        font-size: 0.9rem;
        font-weight: 500;
    }

    li:last-child {
        font-size: 0.75rem;
    }
}

.basket-empty {
    @include themify($themes) {
        color: themed('shoppingBasketBodyFontColor')
    }

    font-weight: 700;
}

.hide-pill {
    visibility: hidden;
}

@media only screen and (max-width: 768px) {
    .shoppingBasketContainerMain {
        width: 300px;
        right: -31%;
    }
}
