@import '../../themes.scss';

// Extra small screen / phone
$screen-xs-min: 480px;
$screen-xs-min: 30.000em;

// Small screen / tablet
$screen-sm-min: 768px;
$screen-sm-min: 48.000em;

// Medium screen / desktop
$screen-md-min: 992px;
$screen-md-min: 62.000em;

// Large screen / wide desktop
$screen-lg-min: 1200px;
$screen-lg-min: 75.000em;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: (47.938em);
$screen-sm-max: (61.938em);
$screen-md-max: (74.938em);


.landingPage-container {
    @include themify($themes) {
        background-image: themed('landingPageMainImagePath');
    }

    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 0.9em;

    @media only screen and (max-width: $screen-sm-min) {
        font-size: 0.8em;
    }

    header {
        .header-logo-container {
            width: 100%;
        }
    }

    main {

        @media only screen and (max-width: $screen-sm-min) {
            display: block;
        }

        .welcome {
            display: block;

            @include themify($themes) {
                background-image: themed('landingPageMainImageOverlay');
            }

            padding: 5px 20px;

            p {
                margin: .5em 0 0 0;
                padding: 0;
            }

            .title {
                @include themify($themes) {
                    font-family: themed('customFontFamily'), Monserrat, Arial,Verdana,Helvetica,sans-serif;
                }

                font-size: 1.2em;
            }

            .sub-title {
                text-align: justify;
                font-size: 1em;
            }

            border-bottom: 2px solid white !important;
            border-radius: 0px;
        }

        .landing-page-info {
            margin: 0;
            align-items: stretch;

            .card-group-product {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 40px;
            }

            .card-product {
                background: inherit;
                flex: 0 1 calc(25% - 1em);
                min-width: 300px; //320 is the smallest mobile width we will make it fit to without scroll bars
                //max-width: 22%;
                @include themify($themes) {
                    background-image: themed('landingPageMainImageOverlay');
                }

                text-align: center;
                border: 2px solid white !important;
                border-radius: 0px;
                margin: 5px !important;

                @media only screen and (max-width: $screen-sm-min) {
                    margin: 20px 5px !important;
                    padding: 0;
                }
            }

            .card-body-product {
                padding: 5px;

                @media only screen and (max-width: $screen-sm-min) {
                    padding: 0;
                }

                p {
                    margin: 0 auto 1em auto;

                    @media only screen and (max-width: $screen-sm-min) {
                        margin: 0;
                    }
                }

                li {
                    font-size: 0.9em;
                }
            }

            .card-title-product {
                //font-size: 1em;
                font-weight: 400;
            }

            .card-text-product {
                text-align: center;
                margin: 0 auto;
                padding: 0 5px;
                font-weight: bold;
            }

            .card-bullet-list-product {
                display: inline-block;
                text-align: left;

                @media only screen and (max-width: $screen-sm-min) {
                    margin-top: 5px !important;
                    margin: 0 auto;
                    max-width: 100%;
                }
            }

            .defacto-container {
                .defacto-image {
                    object-fit: contain;
                    height: 60px;
                }
            }

            .card-footer-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                border-top: none;
            }

            .card-header-product {
                //min-height: 130px;
                border: none;

                .title {
                    font-weight: 600;
                    //font-size: 0.8em;
                    margin-top: 10px;
                    margin-bottom: 2px;

                    @media only screen and (max-width: $screen-sm-min) {
                        //font-size: 1em;
                    }
                }
            }

            .top-image-container {
                height: 50px;
                margin: 0 auto;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }

            .find-out-more-button {
                margin-top: 1em;
                border: 2px solid #fff;
                font-weight: 500;
                padding: 0.25em 3em;

                @include themify($themes) {
                    background: themed('findOutMoreBackgroundColor');
                    color: themed('findOutMoreFontColor');
                    border-color: themed('findOutMoreBorderColor');

                    &:hover {
                        background-color: themed('findOutMoreBackgroundColorHover');
                        color: themed('findOutMoreFontColorHover');
                        border-color: themed('findOutMoreBorderColorHover');
                    }
                }

                @media only screen and (max-width: $screen-sm-min) {
                    width: 100%;
                    padding: 1em 0;
                }
            }

            .alloy-image-landing {
                @include themify($themes) {
                    background-image: themed('alloyImageIconPath');
                }

                object-fit: contain;
            }

            .cosmetic-image-landing {
                @include themify($themes) {
                    background-image: themed('cosmeticImageIconPath');
                }

                object-fit: contain;
            }

            .gap-image-landing {
                @include themify($themes) {
                    background-image: themed('gapImageIconPath');
                }

                object-fit: contain;
            }

            .tyre-image-landing {
                @include themify($themes) {
                    background-image: themed('tyreImageIconPath');
                }

                object-fit: contain;
            }

            .warranty-image-landing {
                @include themify($themes) {
                    background-image: themed('warrantyImageIconPath');
                }

                object-fit: contain;
            }
        }
    }
}
