﻿$themes: (
    autotrust:( 
        headerColor: #223f6e,
        headerLogoPath: url("./images/Autotrust/HeaderLogo.svg"),
        footerColor: #223f6e,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/Autotrust/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87, 87, 87, 0.6)),
        landingPageMainImagePos: center -500px,
        landingPageMainImagePosMobile: calc(50%) bottom,
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #379fdc,
        cardBodyFontColor: #fff,
        cardBorderColor: #fff,
        findOutMoreBackgroundColor: rgba(0,0,0, 0),
        findOutMoreFontColor: #fff,
        findOutMoreBorderColor: #fff,
        findOutMoreBorderColorHover: #fff,
        findOutMoreBackgroundColorHover: #fff,
        findOutMoreFontColorHover: #379fdc,
        alloyImageIconPath: url("../../images/Autotrust/Tyre&Alloy_Logo.png"),
        cosmeticImageIconPath:  url("../../images/Autotrust/Cosmetic_Logo.png"),
        gapImageIconPath: url("../../images/Autotrust/GAP_Logo.png"),
        tyreImageIconPath: url("../../images/Autotrust/Tyre&Alloy_Logo.png"),
        navigationBackgroundColor: #fff,
        navigationBorderColor: #223f6e,
        navigationFontColor: #223f6e,
        navigationBackgroundColorActive: #379fdc,
        navigationBorderColorActive:#379fdc,
        navigationFontColorActive: #fff,
	    navigationAlloyImage: url("../../../images/Autotrust/Tyre_Alloy_Logo_Disabled.png"),
        navigationCosmeticImage:  url("../../../images/Autotrust/Cosmetic_Logo_Disabled.png"),
        navigationGapImage: url("../../../images/Autotrust/GAP_Logo_Nav.png"),
        navigationTyreImage: url("../../../images/Autotrust/Tyre_Alloy_Logo_Disabled.png"),        
	    navigationBackgroundColor_Completed: #223f6e,
        navigationBorder_Completed: rgba(0,0,0,0),
        navigationFontColor_Completed: #fff,
	    navigationAlloyImage_Completed: url("../../../images/Autotrust/Tyre&Alloy_Logo.png"),
        navigationCosmeticImage_Completed: url("../../../images/Autotrust/Cosmetic_Logo.png"),
        navigationGapImage_Completed: url("../../../images/Autotrust/GAP_Logo_Nav.png"),
        navigationTyreImage_Completed: url("../../../images/Autotrust/Tyre&Alloy_Logo.png"),
    	navigationAlloyImage_Active: url("../../../images/Autotrust/Tyre&Alloy_Logo.png"),
        navigationCosmeticImage_Active: url("../../../images/Autotrust/Cosmetic_Logo.png"),
        navigationGapImage_Active: url("../../../images/Autotrust/GAP_Logo_Nav.png"),
        navigationTyreImage_Active: url("../../../images/Autotrust/Tyre&Alloy_Logo.png"),
        shoppingBasketHeaderColor: #379fdc,
        shoppingBasketBodyColor: #223f6e ,
        shoppingBasketFooterColor: #379fdc,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #FFF,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #FFF,
        shoppingBasketPillColor: #379fdc,
        shoppingBasketPillFontColor: #FFF,
        buttonThemeBackgroundColor: #379fdc, 
        buttonThemeFontColor: #FFF,
        buttonThemePaymentButtonBackgroundColor: #379fdc, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #fff,
        buttonThemeBackgroundColorSecondary: #223f6e,
        buttonThemeFontColorSecondary: #fff,
        buttonThemeBorderColorSecondary: #223f6e,
        buttonThemeBackgroundColorCompleted: #223f6e,
        buttonThemeBorderColorCompleted: #223f6e,
        buttonThemeFontColorCompleted: #fff,
        loadingSpinnerColor: #379fdc,
        videoBarColor: #379fdc,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #223f6e,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F5F5F5,
        productDocumentRowFontColor: #000,
        productCardBorder: rgba(0,0,0,0),
        addToBasketBackgroundColor: #223f6e,
        addToBasketBorderColor: #223f6e,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url(""),
        cosmeticProductImagePath:  url(""),
        gapProductImagePath: url(""),
        tyreProductImagePath: url(""),
        buttonThemeFindVehicleColor: #379fdc,
        buttonThemeFindVehicleBorderColor: #379fdc,
        buttonThemeFindVehicleFontColor: #fff,
        buttonThemeFindAdressColor: #379fdc,
        buttonThemeFindAdressBorderColor: #379fdc,
        buttonThemeFindAdressFontColor: #fff,
    ),
        heritageAutomotive: ( 
        headerColor: #000,
        headerLogoPath: url("./images/HeritageAutomotive/HeaderLogo.png"),
        footerColor: #000,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/HeritageAutomotive/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(45, 45, 45, 0.6), rgba(45, 45, 45, 0.6)),
        landingPageMainImagePos: center -500px,
        landingPageMainImagePosMobile: calc(50%) bottom,
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #CCCCCC,
        cardBodyFontColor: #000,
        cardBorderColor: #000,
        findOutMoreBackgroundColor: #EF650A,
        findOutMoreFontColor: #FFF,
        findOutMoreBorderColor: #EF650A,
        findOutMoreBorderColorHover: #FFF,
        findOutMoreBackgroundColorHover: #FFF,
        findOutMoreFontColorHover: #EF650A,
        alloyImageIconPath: url("../../images/HeritageAutomotive/Tyre&Alloy_Logo.png"),
        cosmeticImageIconPath:  url("../../images/HeritageAutomotive/Cosmetic_Logo.png"),
        gapImageIconPath: url("../../images/HeritageAutomotive/GAP_Logo.png"),
        tyreImageIconPath: url("../../images/HeritageAutomotive/Tyre&Alloy_Logo.png"),
        navigationBackgroundColor: #000,
        navigationBorderColor: rgba(0,0,0,0),
        navigationFontColor: #fff,
        navigationBackgroundColorActive: #EF650A,
        navigationBorderColorActive:#EF650A,
        navigationFontColorActive: #fff,
	    navigationAlloyImage: url("../../../images/HeritageAutomotive/Tyre&Alloy_Logo.png"),
        navigationCosmeticImage:  url("../../../images/HeritageAutomotive/Cosmetic_Logo.png"),
        navigationGapImage: url("../../../images/HeritageAutomotive/GAP_Logo.png"),
        navigationTyreImage: url("../../../images/HeritageAutomotive/Tyre&Alloy_Logo.png"),        
	    navigationBackgroundColor_Completed: #CCCCCC,
        navigationBorder_Completed: rgba(0,0,0,0),
        navigationFontColor_Completed: #000,
	    navigationAlloyImage_Completed: url("../../../images/HeritageAutomotive/Tyre_Alloy_Logo_Disabled.png"),
        navigationCosmeticImage_Completed: url("../../../images/HeritageAutomotive/Cosmetic_Logo_Disabled.png"),
        navigationGapImage_Completed: url("../../../images/HeritageAutomotive/GAP_Logo_Disabled.png"),
        navigationTyreImage_Completed: url("../../../images/HeritageAutomotive/Tyre_Alloy_Logo_Disabled.png"),
	    navigationAlloyImage_Active: url("../../../images/HeritageAutomotive/Tyre&Alloy_Logo.png"),
        navigationCosmeticImage_Active:  url("../../../images/HeritageAutomotive/Cosmetic_Logo.png"),
        navigationGapImage_Active: url("../../../images/HeritageAutomotive/GAP_Logo.png"),
        navigationTyreImage_Active: url("../../../images/HeritageAutomotive/Tyre&Alloy_Logo.png"), 
        shoppingBasketHeaderColor: #EF650A,
        shoppingBasketBodyColor: #000 ,
        shoppingBasketFooterColor: #EF650A,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #FFF,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #FFF,
        shoppingBasketPillColor: #EF650A,
        shoppingBasketPillFontColor: #FFF,
        buttonThemeBackgroundColor: #EF650A, 
        buttonThemeFontColor: #FFF,
        buttonThemePaymentButtonBackgroundColor: #EF650A, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #EF650A,
        buttonThemeBackgroundColorSecondary: #000,
        buttonThemeFontColorSecondary: #fff,
        buttonThemeBorderColorSecondary: #000,
        buttonThemeBackgroundColorCompleted: #CCCCCC,
        buttonThemeBorderColorCompleted: #CCCCCC,
        buttonThemeFontColorCompleted: #000,
        loadingSpinnerColor: #EF650A,
        videoBarColor: #CCCCCC,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #000,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F5F5F5,
        productDocumentRowFontColor: #000,
        productCardBorder: #000,
        addToBasketBackgroundColor: #EF650A,
        addToBasketBorderColor: #EF650A,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url(""),
        cosmeticProductImagePath:  url(""),
        gapProductImagePath: url(""),
        tyreProductImagePath: url(""),
        buttonThemeFindVehicleColor: #EF650A,
        buttonThemeFindVehicleBorderColor: #EF650A,
        buttonThemeFindVehicleFontColor: #fff,
        buttonThemeFindAddressColor: #EF650A,
        buttonThemeFindAddressBorderColor: #EF650A,
        buttonThemeFindAddressFontColor: #fff,
    ),
        marshall: ( 
        headerColor: #7D7D7D,
        headerLogoPath: url("./images/Marshall/HeaderLogo.svg"),
        footerColor: #7D7D7D,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/Marshall/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87, 87, 87, 0.6)),
        landingPageMainImagePos: center -250px,
        landingPageMainImagePosMobile: calc(50%) bottom,
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #7D7D7D,
        cardBodyFontColor: #fff,
        cardBorderColor: #fff,
        findOutMoreBackgroundColor: #BA0C2F,
        findOutMoreFontColor: #fff,
        findOutMoreBorderColor: #BA0C2F,
        findOutMoreBorderColorHover: #97999B,
        findOutMoreBackgroundColorHover: #97999B,
        findOutMoreFontColorHover: #FFF,
        alloyImageIconPath: url("../../images/Marshall/Tyre&Alloy_Logo.png"),
        cosmeticImageIconPath:  url("../../images/Marshall/Cosmetic_Logo.png"),
        gapImageIconPath: url("../../images/Marshall/GAP_Logo.png"),
        tyreImageIconPath: url("../../images/Marshall/Tyre&Alloy_Logo.png"),
        navigationBackgroundColor: #7D7D7D,
        navigationBorderColor: rgba(0,0,0,0),
        navigationFontColor: #fff,
        navigationBackgroundColorActive: #BA0C2F,
        navigationBorderColorActive:#BA0C2F,
        navigationFontColorActive: #fff,
	    navigationAlloyImage: url("../../../images/Marshall/Tyre&Alloy_Logo.png"),
        navigationCosmeticImage:  url("../../../images/Marshall/Cosmetic_Logo.png"),
        navigationGapImage: url("../../../images/Marshall/GAP_Logo.png"),
        navigationTyreImage: url("../../../images/Marshall/Tyre&Alloy_Logo.png"),        
	    navigationBackgroundColor_Completed: #97999B,
        navigationBorder_Completed: #000,
        navigationFontColor_Completed: #000,
	    navigationAlloyImage_Completed: url("../../../images/Marshall/Tyre_Alloy_Logo_Disabled.png"),
        navigationCosmeticImage_Completed: url("../../../images/Marshall/Cosmetic_Logo_Disabled.png"),
        navigationGapImage_Completed: url("../../../images/Marshall/GAP_Logo_Disabled.png"),
        navigationTyreImage_Completed: url("../../../images/Marshall/Tyre_Alloy_Logo_Disabled.png"),
	    navigationAlloyImage_Active: url("../../../images/Marshall/Tyre&Alloy_Logo.png"),
        navigationCosmeticImage_Active:  url("../../../images/Marshall/Cosmetic_Logo.png"),
        navigationGapImage_Active: url("../../../images/Marshall/GAP_Logo.png"),
        navigationTyreImage_Active: url("../../../images/Marshall/Tyre&Alloy_Logo.png"), 
        shoppingBasketHeaderColor: #BA0C2F,
        shoppingBasketBodyColor: #97999B ,
        shoppingBasketFooterColor: #BA0C2F,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #FFF,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #FFF,
        shoppingBasketPillColor: #BA0C2F,
        shoppingBasketPillFontColor: #FFF,
        buttonThemeBackgroundColor: #BA0C2F, 
        buttonThemeFontColor: #FFF,
        buttonThemePaymentButtonBackgroundColor: #BA0C2F, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #BA0C2F,
        buttonThemeBackgroundColorSecondary: #7D7D7D,
        buttonThemeFontColorSecondary: #fff,
        buttonThemeBorderColorSecondary: #7D7D7D,
        buttonThemeBackgroundColorCompleted: #97999B ,
        buttonThemeBorderColorCompleted: rgb(217, 217, 217),
        buttonThemeFontColorCompleted: #000,
        loadingSpinnerColor: #BA0C2F,
        videoBarColor: #7D7D7D,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #7D7D7D,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F5F5F5,
        productDocumentRowFontColor: #000,
        productCardBorder: rgba(0,0,0,0),
        addToBasketBackgroundColor: #BA0C2F,
        addToBasketBorderColor: #BA0C2F,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url(""),
        cosmeticProductImagePath:  url(""),
        gapProductImagePath: url(""),
        tyreProductImagePath: url(""),
        buttonThemeFindVehicleColor: #BA0C2F,
        buttonThemeFindVehicleBorderColor: #BA0C2F,
        buttonThemeFindVehicleFontColor: #fff,
        buttonThemeFindAddressColor: #BA0C2F,
        buttonThemeFindAddressBorderColor: #BA0C2F,
        buttonThemeFindAddressFontColor: #fff,
    ), 
        trustFord: ( 
        headerColor: #fff,
        headerLogoPath: url("./images/TrustFord/HeaderLogo.svg"),
        footerColor: #b6b6b6,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/TrustFord/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87,87,87,0.6)),
        landingPageMainImagePos: center -300px,
        landingPageMainImagePosMobile: calc(50%) calc(100%),
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #F4F4F4,
        cardBodyFontColor: #000,
        cardBorderColor: #425968,
        findOutMoreBackgroundColor: #3fb6e4,
        findOutMoreFontColor: #FFF,
        findOutMoreBorderColor: #3fb6e4,
        findOutMoreBorderColorHover: #63c2e7,
        findOutMoreBackgroundColorHover: #63c2e7,
        findOutMoreFontColorHover: #FFF,
        alloyImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        cosmeticImageIconPath:  url("../../images/TrustFord/Cosmetic_Logo.png"),
        gapImageIconPath: url("../../images/TrustFord/GAP_Logo.png"),
        tyreImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        navigationBackgroundColor: #425968,
        navigationBorderColor: rgba(0,0,0,0),
        navigationFontColor: #fff,
        navigationBackgroundColorActive: #63c2e7,
        navigationBorderColorActive:#63c2e7,
        navigationFontColorActive: #fff,
	    navigationAlloyImage: url("../../../images/TrustFord/Tyre&Alloy_Logo.png"),
        navigationCosmeticImage:  url("../../../images/TrustFord/Cosmetic_Logo.png"),
        navigationGapImage: url("../../../images/TrustFord/GAP_Logo.png"),
        navigationTyreImage: url("../../../images/TrustFord/Tyre&Alloy_Logo.png"), 
	    navigationAlloyImage_Active: url("../../../images/TrustFord/Tyre&Alloy_Logo.png"),
        navigationCosmeticImage_Active:  url("../../../images/TrustFord/Cosmetic_Logo.png"),
        navigationGapImage_Active: url("../../../images/TrustFord/GAP_Logo.png"),
        navigationTyreImage_Active: url("../../../images/TrustFord/Tyre&Alloy_Logo.png"), 
	    navigationBackgroundColor_Completed: #fff,
        navigationBorder_Completed: #000,
        navigationFontColor_Completed: #000,
	    navigationAlloyImage_Completed: url("../../../images/TrustFord/Tyre_Alloy_Logo_Disabled.png"),
        navigationCosmeticImage_Completed: url("../../../images/TrustFord/Cosmetic_Logo_Disabled.png"),
        navigationGapImage_Completed: url("../../../images/TrustFord/GAP_Logo_Disabled.png"),
        navigationTyreImage_Completed: url("../../../images/TrustFord/Tyre_Alloy_Logo_Disabled.png"),
        shoppingBasketHeaderColor: #3fb6e4,
        shoppingBasketBodyColor: #fff ,
        shoppingBasketFooterColor: #3fb6e4,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #000,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #425968,
        shoppingBasketPillColor: #63c2e7,
        shoppingBasketPillFontColor: #FFF,
        buttonThemeBackgroundColor: #63c2e7, 
        buttonThemeFontColor: #FFF,
        buttonThemePaymentButtonBackgroundColor: #63c2e7, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #63c2e7,
        buttonThemeBackgroundColorSecondary: #425968,
        buttonThemeFontColorSecondary: #fff,
        buttonThemeBorderColorSecondary: #425968,
        buttonThemeBackgroundColorCompleted: #fff,
        buttonThemeBorderColorCompleted: #000,
        buttonThemeFontColorCompleted: #000,
        loadingSpinnerColor: #63c2e7,
        videoBarColor: #425968,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #63c2e7,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F5F5F5,
        productDocumentRowFontColor: #000,
        productCardBorder: #425968,
        addToBasketBackgroundColor: #425968,
        addToBasketBorderColor: #425968,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url(""),
        cosmeticProductImagePath:  url(""),
        gapProductImagePath: url(""),
        tyreProductImagePath: url(""),
        buttonThemeFindVehicleColor: #63c2e7,
        buttonThemeFindVehicleBorderColor: #63c2e7,
        buttonThemeFindVehicleFontColor: #fff,
        buttonThemeFindAddressColor: #63c2e7,
        buttonThemeFindAddressBorderColor: #63c2e7,
        buttonThemeFindAddressFontColor: #fff,
    ), 
        audi: ( 
        headerColor: #fff,
        headerLogoPath: url("./images/Audi/HeaderLogo.png"),
        footerColor: #1a1a1a,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/Audi/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87,87,87,0.6)),
        landingPageMainImagePos: center -300px,
        landingPageMainImagePosMobile: calc(50%) calc(100%),
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #F2F2F2,
        cardBodyFontColor: #1a1a1a,
        cardBorderColor: #F2F2F2,
        findOutMoreBackgroundColor: #bb0a30,
        findOutMoreFontColor: #FFF,
        findOutMoreBorderColor: #bb0a30,
        findOutMoreBorderColorHover: #bb0a30,
        findOutMoreBackgroundColorHover: #bb0a30,
        findOutMoreFontColorHover: #FFF,
        alloyImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        cosmeticImageIconPath:  url("../../images/TrustFord/Cosmetic_Logo.png"),
        gapImageIconPath: url("../../images/TrustFord/GAP_Logo.png"),
        tyreImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        navigationBackgroundColor: #fff,
        navigationBorderColor: #1a1a1a,
        navigationFontColor: #1a1a1a,
        navigationBackgroundColorActive: #1a1a1a,
        navigationBorderColorActive:#1a1a1a,
        navigationFontColorActive: #fff,
	    navigationBackgroundColor_Completed: #f2f2f2,
        navigationBorder_Completed: #1a1a1a,
        navigationFontColor_Completed: #1a1a1a,
        shoppingBasketHeaderColor: #1a1a1a,
        shoppingBasketBodyColor: #fff,
        shoppingBasketFooterColor: #1a1a1a,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #1a1a1a,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #1a1a1a,
        shoppingBasketPillColor: #1a1a1a,
        shoppingBasketPillFontColor: #fff,
        buttonThemeBackgroundColor: #bb0a30, 
        buttonThemeFontColor: #fff,
        buttonThemePaymentButtonBackgroundColor: #bb0a30, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #63c2e7,
        buttonThemeBackgroundColorSecondary: #B6B1A9,
        buttonThemeFontColorSecondary: #1a1a1a,
        buttonThemeBorderColorSecondary: #B6B1A9,
        buttonThemeBackgroundColorCompleted: #B6B1A9,
        buttonThemeBorderColorCompleted: #1a1a1a,
        buttonThemeFontColorCompleted: #1a1a1a,
        loadingSpinnerColor: #bb0a30,
        videoBarColor: #B6B1A9,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #1a1a1a,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F2F2F2,
        productDocumentRowFontColor: #000,
        productCardBorder: #fff,
        addToBasketBackgroundColor: #bb0a30,
        addToBasketBorderColor: #bb0a30,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url("../../../images/Audi/Products/Alloy.jpg"),
        cosmeticProductImagePath: url("../../../images/Audi/Products/Cosmetic.jpg"),
        gapProductImagePath: url("../../../images/Audi/Products/Gap.jpg"),
        tyreProductImagePath: url("../../../images/Audi/Products/Tyre.jpg"),
        buttonThemeFindVehicleColor: #B6B1A9,
        buttonThemeFindVehicleBorderColor: #B6B1A9,
        buttonThemeFindVehicleFontColor: #1a1a1a,
        buttonThemeFindAddressColor: #B6B1A9,
        buttonThemeFindAddressBorderColor: #B6B1A9,
        buttonThemeFindAddressFontColor: #1a1a1a,
        customFontFamily: audiTypeV01,
    ), 
    vwcommercial: ( 
        headerColor: #fff,
        headerLogoPath: url("./images/VWCommercial/HeaderLogo.png"),
        footerColor: #1a1a1a,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/VWCommercial/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87,87,87,0.6)),
        landingPageMainImagePos: center -300px,
        landingPageMainImagePosMobile: calc(50%) calc(100%),
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #FFF,
        cardBodyFontColor: #1a1a1a,
        cardBorderColor: #F2F2F2,
        findOutMoreBackgroundColor: #00275A,
        findOutMoreFontColor: #FFF,
        findOutMoreBorderColor: #00275A,
        findOutMoreBorderColorHover: #00275A,
        findOutMoreBackgroundColorHover: #00275A,
        findOutMoreFontColorHover: #FFF,
        alloyImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        cosmeticImageIconPath:  url("../../images/TrustFord/Cosmetic_Logo.png"),
        gapImageIconPath: url("../../images/TrustFord/GAP_Logo.png"),
        tyreImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        navigationBackgroundColor: #fff,
        navigationBorderColor: #1a1a1a,
        navigationFontColor: #1a1a1a,
        navigationBackgroundColorActive: #00275A,
        navigationBorderColorActive:#1a1a1a,
        navigationFontColorActive: #fff,
	    navigationBackgroundColor_Completed: #fff,
        navigationBorder_Completed: #1a1a1a,
        navigationFontColor_Completed: #1a1a1a,
        shoppingBasketHeaderColor: #00275A,
        shoppingBasketBodyColor: #fff,
        shoppingBasketFooterColor: #00275A,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #1a1a1a,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #1a1a1a,
        shoppingBasketPillColor: #1a1a1a,
        shoppingBasketPillFontColor: #fff,
        buttonThemeBackgroundColor: #00275A, 
        buttonThemeFontColor: #fff,
        buttonThemePaymentButtonBackgroundColor: #00275A, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #00275A,
        buttonThemeBackgroundColorSecondary: #00275A,
        buttonThemeFontColorSecondary: #FFF,
        buttonThemeBorderColorSecondary: #00275A,
        buttonThemeBackgroundColorCompleted: #fff,
        buttonThemeBorderColorCompleted: #1a1a1a,
        buttonThemeFontColorCompleted: #1a1a1a,
        loadingSpinnerColor: #00275A,
        videoBarColor: #425968,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #00275A,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F2F2F2,
        productDocumentRowFontColor: #000,
        productCardBorder: #fff,
        addToBasketBackgroundColor: #00275A,
        addToBasketBorderColor: #00275A,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url("../../../images/VWCommercial/Products/Alloy.jpg"),
        cosmeticProductImagePath: url("../../../images/VWCommercial/Products/Cosmetic.jpg"),
        gapProductImagePath: url("../../../images/VWCommercial/Products/Gap.jpg"),
        tyreProductImagePath: url("../../../images/VWCommercial/Products/Tyre.jpg"),
        buttonThemeFindVehicleColor: #00275A,
        buttonThemeFindVehicleBorderColor: #00275A,
        buttonThemeFindVehicleFontColor: #FFF,
        buttonThemeFindAddressColor: #00275A,
        buttonThemeFindAddressBorderColor: #00275A,
        buttonThemeFindAddressFontColor: #FFF,
        customFontFamily: VWCommercial,
    ), 
    seat: ( 
        headerColor: #fff,
        headerLogoPath: url("./images/SEAT/HeaderLogo.png"),
        footerColor: #33302E,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/SEAT/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87,87,87,0.6)),
        landingPageMainImagePos: center -300px,
        landingPageMainImagePosMobile: calc(50%) calc(100%),
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #9FB9AE,
        cardBodyFontColor: #FFF,
        cardBorderColor: #F2F2F2,
        findOutMoreBackgroundColor: #EA5D1A,
        findOutMoreFontColor: #FFF,
        findOutMoreBorderColor: #EA5D1A,
        findOutMoreBorderColorHover: #EA5D1A,
        findOutMoreBackgroundColorHover: #EA5D1A,
        findOutMoreFontColorHover: #FFF,
        alloyImageIconPath: url(""),
        cosmeticImageIconPath:  url(""),
        gapImageIconPath: url(""),
        tyreImageIconPath: url(""),
        navigationBackgroundColor: #fff,
        navigationBorderColor: #9FB9AE,
        navigationFontColor: #33302E,
        navigationBackgroundColorActive: #9FB9AE,
        navigationBorderColorActive: #9FB9AE,
        navigationFontColorActive: #33302E,
	    navigationBackgroundColor_Completed: #fff,
        navigationBorder_Completed: #9FB9AE,
        navigationFontColor_Completed: #33302E,
        shoppingBasketHeaderColor: #9FB9AE,
        shoppingBasketBodyColor: #fff,
        shoppingBasketFooterColor: #9FB9AE,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #33302e,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #9FB9AE,
        shoppingBasketPillColor: #9FB9AE,
        shoppingBasketPillFontColor: #fff,
        buttonThemeBackgroundColor: #EA5D1A, 
        buttonThemeFontColor: #fff,
        buttonThemePaymentButtonBackgroundColor: #33302E, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #33302E,
        buttonThemeBackgroundColorSecondary: #FFF,
        buttonThemeFontColorSecondary: #EA5D1A,
        buttonThemeBorderColorSecondary: #EA5D1A,
        buttonThemeBackgroundColorCompleted: #fff,
        buttonThemeBorderColorCompleted: #EA5D1A,
        buttonThemeFontColorCompleted: #EA5D1A,
        loadingSpinnerColor: #9FB9AE,
        videoBarColor: #B6B1A9,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #33302E,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #FFF,
        productDocumentRowFontColor: #EA5D1A,
        productCardBorder: #9FB9AE,
        addToBasketBackgroundColor: #33302E,
        addToBasketBorderColor: #33302E,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url("../../../images/SEAT/Products/Alloy.jpg"),
        cosmeticProductImagePath: url("../../../images/SEAT/Products/Cosmetic.jpg"),
        gapProductImagePath: url("../../../images/SEAT/Products/Gap.jpg"),
        tyreProductImagePath: url("../../../images/SEAT/Products/Tyre.jpg"),
        buttonThemeFindVehicleColor: #fff,
        buttonThemeFindVehicleBorderColor: #EA5D1A,
        buttonThemeFindVehicleFontColor: #EA5D1A,
        buttonThemeFindAddressColor: #fff,
        buttonThemeFindAddressBorderColor: #EA5D1A,
        buttonThemeFindAddressFontColor: #EA5D1A,
        customFontFamily: SeatBcn,
    ), 
        vw: ( 
        headerColor: #fff,
        headerLogoPath: url("./images/VW/HeaderLogo.jpg"),
        footerColor: #1a1a1a,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/VW/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87,87,87,0.6)),
        landingPageMainImagePos: center -300px,
        landingPageMainImagePosMobile: calc(50%) calc(100%),
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #FFF,
        cardBodyFontColor: #1a1a1a,
        cardBorderColor: #F2F2F2,
        findOutMoreBackgroundColor: #00275A,
        findOutMoreFontColor: #FFF,
        findOutMoreBorderColor: #00275A,
        findOutMoreBorderColorHover: #00275A,
        findOutMoreBackgroundColorHover: #00275A,
        findOutMoreFontColorHover: #FFF,
        alloyImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        cosmeticImageIconPath:  url("../../images/TrustFord/Cosmetic_Logo.png"),
        gapImageIconPath: url("../../images/TrustFord/GAP_Logo.png"),
        tyreImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        navigationBackgroundColor: #fff,
        navigationBorderColor: #1a1a1a,
        navigationFontColor: #1a1a1a,
        navigationBackgroundColorActive: #00275A,
        navigationBorderColorActive:#1a1a1a,
        navigationFontColorActive: #fff,
	    navigationBackgroundColor_Completed: #fff,
        navigationBorder_Completed: #1a1a1a,
        navigationFontColor_Completed: #1a1a1a,
        shoppingBasketHeaderColor: #00275A,
        shoppingBasketBodyColor: #fff,
        shoppingBasketFooterColor: #00275A,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #1a1a1a,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #1a1a1a,
        shoppingBasketPillColor: #1a1a1a,
        shoppingBasketPillFontColor: #fff,
        buttonThemeBackgroundColor: #00275A, 
        buttonThemeFontColor: #fff,
        buttonThemePaymentButtonBackgroundColor: #00275A, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #00275A,
        buttonThemeBackgroundColorSecondary: #00275A,
        buttonThemeBorderColorSecondary: #1a1a1a,
        buttonThemeFontColorSecondary: #FFF,
        buttonThemeBackgroundColorCompleted: #fff,
        buttonThemeBorderColorCompleted: #1a1a1a,
        buttonThemeFontColorCompleted: #1a1a1a,
        loadingSpinnerColor: #00275A,
        videoBarColor: #425968,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #00275A,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F2F2F2,
        productDocumentRowFontColor: #000,
        productCardBorder: #fff,
        addToBasketBackgroundColor: #00275A,
        addToBasketBorderColor: #00275A,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url("../../../images/VW/Products/Alloy.jpg"),
        cosmeticProductImagePath: url("../../../images/VW/Products/Cosmetic.jpg"),
        gapProductImagePath: url("../../../images/VW/Products/Gap.jpg"),
        tyreProductImagePath: url("../../../images/VW/Products/Tyre.jpg"),
        buttonThemeFindVehicleColor: #00275A,
        buttonThemeFindVehicleBorderColor: #00275A,
        buttonThemeFindVehicleFontColor: #FFF,
        buttonThemeFindAddressColor: #00275A,
        buttonThemeFindAddressBorderColor: #00275A,
        buttonThemeFindAddressFontColor: #FFF,
        customFontFamily: VW,
    ),
    skoda: ( 
        headerColor: #fff,
        headerLogoPath: url("./images/Skoda/HeaderLogo.png"),
        footerColor: #1a1a1a,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/Skoda/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87,87,87,0.6)),
        landingPageMainImagePos: center -300px,
        landingPageMainImagePosMobile: calc(50%) calc(100%),
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #cecece,
        cardBodyFontColor: #1a1a1a,
        cardBorderColor: #F2F2F2,
        findOutMoreBackgroundColor: #4ba82e,
        findOutMoreFontColor: #FFF,
        findOutMoreBorderColor: #4ba82e,
        findOutMoreBorderColorHover: #4ba82e,
        findOutMoreBackgroundColorHover: #4ba82e,
        findOutMoreFontColorHover: #FFF,
        alloyImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        cosmeticImageIconPath:  url("../../images/TrustFord/Cosmetic_Logo.png"),
        gapImageIconPath: url("../../images/TrustFord/GAP_Logo.png"),
        tyreImageIconPath: url("../../images/TrustFord/Tyre&Alloy_Logo.png"),
        navigationBackgroundColor: #fff,
        navigationBorderColor: #1a1a1a,
        navigationFontColor: #1a1a1a,
        navigationBackgroundColorActive: #4ba82e,
        navigationBorderColorActive:#1a1a1a,
        navigationFontColorActive: #fff,
	    navigationBackgroundColor_Completed: #fff,
        navigationBorder_Completed: #1a1a1a,
        navigationFontColor_Completed: #1a1a1a,
        shoppingBasketHeaderColor: #4ba82e,
        shoppingBasketBodyColor: #fff,
        shoppingBasketFooterColor: #4ba82e,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #1a1a1a,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #1a1a1a,
        shoppingBasketPillColor: #1a1a1a,
        shoppingBasketPillFontColor: #fff,
        buttonThemeBackgroundColor: #4ba82e, 
        buttonThemeFontColor: #fff,
        buttonThemePaymentButtonBackgroundColor: #4ba82e, 
        buttonThemePaymentButtonFontColor: #FFF,
        buttonThemePaymentButtonBorderColor: #4ba82e,
        buttonThemeBackgroundColorSecondary: #4ba82e,
        buttonThemeBorderColorSecondary: #1a1a1a,
        buttonThemeFontColorSecondary: #FFF,
        buttonThemeBackgroundColorCompleted: #fff,
        buttonThemeBorderColorCompleted: #1a1a1a,
        buttonThemeFontColorCompleted: #1a1a1a,
        loadingSpinnerColor: #4ba82e,
        videoBarColor: #425968,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #4ba82e,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F2F2F2,
        productDocumentRowFontColor: #000,
        productCardBorder: #fff,
        addToBasketBackgroundColor: #4ba82e,
        addToBasketBorderColor: #4ba82e,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url("../../../images/Skoda/Products/Alloy.jpg"),
        cosmeticProductImagePath: url("../../../images/Skoda/Products/Cosmetic.jpg"),
        gapProductImagePath: url("../../../images/Skoda/Products/Gap.jpg"),
        tyreProductImagePath: url("../../../images/Skoda/Products/Tyre.jpg"),
        buttonThemeFindVehicleColor: #FFF,
        buttonThemeFindVehicleBorderColor: #cecece,
        buttonThemeFindVehicleFontColor: #484848,
        buttonThemeFindAddressColor: #FFF,
        buttonThemeFindAddressBorderColor: #cecece,
        buttonThemeFindAddressFontColor: #484848,
        customFontFamily: Skoda,
    ),
    driverFirst: ( 
        headerColor: #fff,
        headerLogoPath: url("./images/DriverFirst/HeaderLogo.png"),
        footerColor: #3E4249,
        footerFontColor: #fff,
        landingPageMainImagePath: url("../../images/DriverFirst/Strapline.jpg"),
        landingPageMainImageOverlay: linear-gradient(rgba(87, 87, 87, 0.6), rgba(87,87,87,0.6)),
        landingPageMainImagePos: center -300px,
        landingPageMainImagePosMobile: calc(50%) calc(100%),
        cardHeaderBackgroundColorTransparent: rgba(0,0,0, 0),
        cardBodyBackgroundColor: #F2F2F2,
        cardBodyFontColor: #1a1a1a,
        cardBorderColor: #F2F2F2,
        findOutMoreBackgroundColor: #0087c9,
        findOutMoreFontColor: #fff,
        findOutMoreBorderColor: #0087c9,
        findOutMoreBorderColorHover: #0087c9,
        findOutMoreBackgroundColorHover: #0087c9,
        findOutMoreFontColorHover: #fff,
        warrantyImageIconPath: url("../../images/DriverFirst/Warranty_Logo.png"),
        navigationBackgroundColor: #3E4249,
        navigationBorderColor: #3E4249,
        navigationFontColor: #fff,
        navigationWarrantyImage:  url("../../../images/DriverFirst/Warranty_Logo.png"),
        navigationWarrantyImage_Completed: url("../../../images/DriverFirst/Warranty_Logo_Disabled.png"),
	    navigationWarrantyImage_Active: url("../../../images/DriverFirst/Warranty_Logo.png"),
        navigationBackgroundColorActive: #0087c9,
        navigationBorderColorActive:#0087c9,
        navigationFontColorActive: #fff,
	    navigationBackgroundColor_Completed: #3E4249,
        navigationBorder_Completed: #3E4249,
        navigationFontColor_Completed: #fff,
        shoppingBasketHeaderColor: #1a1a1a,
        shoppingBasketBodyColor: #fff,
        shoppingBasketFooterColor: #1a1a1a,
        shoppingBasketHeaderFontColor: #FFF,
        shoppingBasketBodyFontColor: #1a1a1a,
        shoppingBasketFooterFontColor: #FFF,
        shoppingBasketIconColor: #1a1a1a,
        shoppingBasketPillColor: #1a1a1a,
        shoppingBasketPillFontColor: #fff,
        buttonThemeBackgroundColor: #0087c9, 
        buttonThemeFontColor: #fff,
        buttonThemePaymentButtonBackgroundColor: #0087c9, 
        buttonThemePaymentButtonFontColor: #fff,
        buttonThemePaymentButtonBorderColor: #63c2e7,
        buttonThemeBackgroundColorSecondary: #cccccc,
        buttonThemeFontColorSecondary: #1a1a1a,
        buttonThemeBorderColorSecondary: #cccccc,
        buttonThemeBackgroundColorCompleted: #cccccc,
        buttonThemeBorderColorCompleted: #cccccc,
        buttonThemeFontColorCompleted: #1a1a1a,
        loadingSpinnerColor: #0087c9,
        videoBarColor: #cccccc,
        videoPlayButtonColor: rgba(0,0,0,0),
        productDocumentHeaderBackgroundColor: #0087c9,
        productDocumentHeaderFontColor: #FFF,
        productDocumentRowBackgroundColor: #F2F2F2,
        productDocumentRowFontColor: #000,
        productCardBorder: #fff,
        addToBasketBackgroundColor: #0087c9,
        addToBasketBorderColor: #0087c9,
        addToBasketFontColor: #fff,
        alloyProductImagePath: url(""),
        buttonThemeFindVehicleColor: #cccccc,
        buttonThemeFindVehicleBorderColor: #cccccc,
        buttonThemeFindVehicleFontColor: #1a1a1a,
        buttonThemeFindAddressColor: #cccccc,
        buttonThemeFindAddressBorderColor: #cccccc,
        buttonThemeFindAddressFontColor: #1a1a1a,
    )
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}
