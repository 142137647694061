@import '../../../themes.scss';

.card-payment {
    @include themify($themes) {
        background-color: themed('cardHeaderBackgroundColorTransparent');
    }

    text-align: center;
    border: none;
    border-radius: 0px;

    h4 {
        font-size: 0.9rem;
        width: 80%;
        padding-top: 1rem;
        margin: 0 auto;
        font-weight: 700;
    }

    p {
        width: 90%;
        margin: 0 auto;
    }
}

.card-header-payment {
    @include themify($themes) {
        background-color: themed('cardBodyBackgroundColor');
        color: themed('cardBodyFontColor');
        border-color: themed('cardBorderColor')
    }
}

.card-body-payment {
    @include themify($themes) {
        background-color: themed('cardBodyBackgroundColor');
        color: themed('cardBodyFontColor');
        border-color: themed('cardBorderColor')
    }

    border: none;
    padding-top: 0.5rem;
    padding-bottom: 0;

    p {
        margin: 0 auto 1rem auto;
    }

    ul {
        border-radius: 0px;
    }

    li {
        font-size: 0.9rem;
    }
}

.card-title-payment {
    font-size: 1rem;
    font-weight: 700;
}

.text-explanation {
    font-size: 0.6rem;
}

.card-text-payment {
    font-size: 0.9rem;
}

.card-direct-debit {
    border-radius: 0 !important;
}

.card-header-direct-debit {
    border-radius: 0;

    @include themify($themes) {
        background-color: themed('cardBodyBackgroundColor');
        color: themed('cardBodyFontColor');
        border-color: themed('cardBorderColor')
    }
}

.card-header-direct-debit:first-child {
    border-radius: 0;
}

.border-3 {
    border-width: 3px !important;
}

.direct-debit-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.product-name-spacing {
    margin-right: 1.25rem;
}

.payment-option {
    width: 50%;
}

.product-summary-item {
    display: flex;
}

.payment-panels-container {
    display: flex;
    justify-content: center;
}

.payment-option-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.payment-select-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.tax-inclusive-text {
    font-size: 0.9rem;
    width: 100%;
    margin: 0 auto;
}

.price-items {
    ul {
        list-style-type: none;
        padding: 0px;
    }

    list-style-type: none;
    padding: 0px;
    text-align: left;
}

.price-summary {
    ul {
        list-style-type: none;
        padding: 0px;
    }

    list-style-type: none;
    padding-left: 17%;
    padding-right: 17%;
    text-align: left;
}

.price-total {
    text-align: right;
    padding-right: 0.5rem;
    padding-top: 0.5em;
    list-style-type: none;
}

.iframe-style {
    iframe {
        border: none;
        margin: 0;
        padding:0;
    }
}

@media only screen and (max-width: 520px) {


    .payment-option {
        width: 100%;
    }

    .product-summary-item {
        display: block;
    }

    .payment-panels-container {
        display: block;
    }

    .product-name-spacing {
        margin-right: 0px;
    }
}
