.loading-anim {
    animation-direction: normal;
    animation-duration: 2s;
    animation-name: fade-in-up
}

@keyframes fade-in-up {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.loading-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}
